@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'GloriousCopper';
    src: url('./attachments/fonts/gloriousCopper/GloriousCopper.otf');
    font-weight: normal;
    font-style: normal;
}

.font-GloriousCopper{
  font-family: 'GloriousCopper';
}

.newsletter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: white;
  padding: 20px 0px;
  border-radius: 8px;
  width: 300px;
  margin: 0 auto;
}

.newsletter-container h2 {
  margin-bottom: 20px;
}

.newsletter-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-container input,
.newsletter-container textarea {
  padding: 3px;
  padding-left: 5px;
  padding-right: 30px;
  border-radius: 4px;
  border: none;
  color: black;
  margin-bottom: 10px;
  width: 100%;  
}

.newsletter-container textarea{
  height: 80px;
  resize: none;
}

.newsletter-container button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
}

.newsletter-container button svg {
  fill: white;
}

.newsletter-container button svg:hover {
  fill: #ccc;
}